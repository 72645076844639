import * as React from "react";
import { CoverCtaContainer } from "../styles";
import GetCoveredButton from "components/common/cover-button";

const CoverCta: React.FC = () => {
  return (
    <CoverCtaContainer className="cover-cta-container">
      <GetCoveredButton
        location="what_we_cover_cta"
        className="get-covered-button"
        text="Cover my trip"
      />
    </CoverCtaContainer>
  );
};

export default CoverCta;
