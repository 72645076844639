import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const CoverTopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.cover-top-container {
    margin: 0 auto;
    max-width: 1440px;
    padding: ${({ theme: { spacing } }: ThemeType) =>
      spacing(22, 10, "max(12vh, 200px)")};
    text-align: center;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding-right: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
      padding-left: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    }

    & > .cover-top-title {
      & > h1 {
        color: ${({ theme }) => theme.palette.primary.main};
        font-family: "utopia-std-display";
        font-size: 80px;
        font-weight: 400;
        line-height: 0.78125;
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(8)};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 64px;
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        }
      }

      & > h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.635;
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 18px;
        }
      }
    }

    & > .cover-top-text-container {
      color: #000;
      font-size: 18px;
      margin: 0 auto;
      max-width: 640px;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 14px;
      }
    }
  }
`;

export const CoverListContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.cover-list-container {
    position: relative;
    & > .background-container {
      position: absolute;
      top: 0;
      min-width: 100vw;
      margin: 0 auto;
      height: 100%;
      left: 0;
      & > .sea-image {
        position: absolute;
        margin: 0 auto;
        min-width: 100vw;
        height: 100%;
      }
    }

    & > .list-container-owner {
      margin: 0 auto;
      max-width: 1440px;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing("8vw", 10, 9)};
      position: relative;
      & > .boat-image {
        position: absolute;
        right: 0;
        transform: translateY(-90%);
        max-width: 1200px;
        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          right: 0;
          width: auto;
        }
      }
      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: ${({ theme: { spacing } }: ThemeType) =>
          spacing("20vw", 2, 6)};
      }

      & > .list-container {
        background: none;
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        gap: ${({ theme: { spacing } }: ThemeType) => spacing(3, 2)};
        justify-content: space-between;
        padding: 0;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          background: white;
          display: block;
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
        }

        & > .list-item {
          align-items: center;
          background: white;
          border-radius: 4px;
          display: flex;
          flex: 0 0
            ${({ theme: { spacing } }: ThemeType) => `calc(33.333333% -
                 ${spacing(1.5)})`};
          flex-direction: column;
          font-size: clamp(16px, 1.5vw, 18px);
          justify-content: center;
          line-height: 1.3425;
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
          position: relative;
          text-align: center;

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            border-radius: 0;
            flex-direction: row;
            font-size: 16px;
            justify-content: flex-start;
            padding: ${({ theme: { spacing } }: ThemeType) => spacing(1.5, 0)};
            text-align: left;
          }

          & + .list-item {
            ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
              border-top: solid 1px
                ${({ theme }) => theme.palette.primary.light};
            }
          }

          & > .cover-type,
          & > .optional {
            border-radius: 4px;
            font-size: 12px;
            line-height: 1.12;
            padding: ${({ theme: { spacing } }: ThemeType) =>
              spacing(0.5, 1.5)};
          }

          & > .cover-type {
            display: inline-block;
            position: absolute;
            top: ${({ theme: { spacing } }: ThemeType) => spacing(-0.75)};

            ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
              display: none;
            }

            &.health {
              background: ${({ theme }) => theme.palette.secondary.main};
            }

            &.stuff {
              background: #fbe8a9;
            }

            &.trip {
              background: ${({ theme }) => theme.palette.p_pink.background};
            }
          }

          & > .optional {
            display: none;

            ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
              background: ${({ theme }) => theme.palette.p_yellow.background};
              display: inline-block;
              margin-left: auto;
            }
          }
        }
      }

      & > .cover-list-footnote {
        font-size: 18px;
        line-height: 1.32;
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(9)};
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 4)};
        text-align: center;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
        }
      }
    }
  }
`;

export const CoverCtaContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.cover-cta-container {
    margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
    text-align: center;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      display: none;
    }

    & > .get-covered-button {
      font-size: 18px;
      width: 230px;
      height: 48px;
      margin: 0 auto;
      text-transform: none;
    }
  }
`;

export const TidbitsContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.tidbits-container {
    margin: 0 auto;
    max-width: 1440px;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(14, 12, 6)};

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(6, 2)};
    }

    & > h2 {
      color: ${({ theme }) => theme.palette.primary.main};
      font-family: "utopia-std-display";
      font-size: 50px;
      font-weight: 400;
      line-height: 1.185;
      margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(8)};
      text-align: center;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 36px;
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
      }
    }

    & > .tidbits-list {
      column-count: 2;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(5)};
      margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(-4)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        column-count: auto;
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(-2)};
      }

      & > .tidbit {
        background: white;
        display: inline-block;
        margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(4)};

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          display: block;
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
          padding: ${({ theme: { spacing } }: ThemeType) => spacing(4, 3)};
        }

        & > h3 {
          font-family: "utopia-std-display";
          font-size: 24px;
          font-weight: 600;
          line-height: 1.77;
          margin-bottom: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 18px;
            line-height: 1.125;
          }
        }

        & > p {
          color: ${({ theme }) => theme.palette.p_black.text};
          font-size: 14px;
          line-height: 1.62;
        }
      }
    }

    & > .tidbits-footnote {
      font-size: 18px;
      line-height: 1.32;
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(9)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 4)};
      text-align: center;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(8)};
      }
    }
  }
`;
