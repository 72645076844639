import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const CoverContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="main" {...props} />
))`
  &.cover-container {
    background-color: ${({ theme: { palette } }: ThemeType) => (
      palette.p_pink.background
    )};
  }
`;
