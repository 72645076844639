import React from "react";
import Cover from "../components/pages/cover";

interface CoverProps {}

const CoverPage: React.FC<CoverProps> = () => {
  return <Cover />;
};

export default CoverPage;
