import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Box from "@mui/material/Box";
import { CoverTopContainer } from "../styles";
import { PrismicRichText } from "@prismicio/react";

const CoverTop: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicCoverPage {
        nodes {
          data {
            title {
              richText
            }
            secondary_title {
              richText
            }
            content_paragraph {
              richText
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <CoverTopContainer className="cover-top-container">
      <Box className="cover-top-title">
        <PrismicRichText field={document.title.richText} />
        <PrismicRichText field={document.secondary_title.richText} />
      </Box>
      <Box className="cover-top-text-container">
        <PrismicRichText field={document.content_paragraph.richText} />
      </Box>
    </CoverTopContainer>
  );
};

export default CoverTop;
