import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Box from "@mui/material/Box";
import { TidbitsContainer } from "../styles";
import { Tidbit } from "../types";
import { PrismicRichText } from "@prismicio/react";

const Tidbits: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicCoverPage {
        nodes {
          data {
            body {
              ... on PrismicCoverPageDataBodyCoverPageTidbits {
                id
                primary {
                  tidbits_title {
                    richText
                  }
                  tidbits_footnote {
                    richText
                  }
                }
                items {
                  tidbit_title {
                    richText
                  }
                  tidbit_paragraph {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const document = data.pageData.nodes[0].data.body[1];

  return (
    <TidbitsContainer className="tidbits-container">
      <PrismicRichText field={document.primary.tidbits_title.richText} />

      <Box className="tidbits-list">
        {document.items.map((item: Tidbit, index: number) => (
          <Box
            className="tidbit"
            key={`${item.tidbit_title.richText[0]?.text}-${index}`}
          >
            <PrismicRichText field={item.tidbit_title.richText} />
            <PrismicRichText field={item.tidbit_paragraph.richText} />
          </Box>
        ))}
      </Box>

      <Box className="tidbits-footnote">
        <PrismicRichText field={document.primary.tidbits_footnote.richText} />
      </Box>
    </TidbitsContainer>
  );
};

export default Tidbits;
