import * as React from "react";
import Seo from "components/common/seo";
import CoverCta from "./components/cover-cta";
import CoverList from "./components/cover-list";
import CoverTop from "./components/top";
import Tidbits from "./components/tidbits";
import { CoverProps } from "./types";
import { CoverContainer } from "./styles";

const Cover: React.FC<CoverProps> = () => {
  return (
    <>
      <Seo />

      <CoverContainer className="cover-container">
        <CoverTop />
        <CoverList />
        <CoverCta />
        <Tidbits />
      </CoverContainer>
    </>
  );
};

export default Cover;
