import classNames from "classnames";
import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Box } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { CoverListContainer } from "../styles";
import { CoverListItem } from "../types";
import { PrismicRichText } from "@prismicio/react";

const CoverList: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicCoverPage {
        nodes {
          data {
            body {
              ... on PrismicCoverPageDataBodyHomepageSecondFoldAdditional {
                id
                items {
                  content_paragraph {
                    richText
                  }
                  cover_type
                  is_add_on
                }
                primary {
                  boat_image {
                    gatsbyImageData
                  }
                  sea_image {
                    gatsbyImageData
                  }
                  cover_list_footnote {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const document = data.pageData.nodes[0].data.body[0];

  return (
    <CoverListContainer className="cover-list-container">
      <Box className="background-container">
        <GatsbyImage
          image={document.primary.sea_image.gatsbyImageData}
          alt=""
          className="sea-image"
        />
      </Box>
      <Box className="list-container-owner">
        <GatsbyImage
          image={document.primary.boat_image.gatsbyImageData}
          alt=""
          className="boat-image"
        />
        <Box className="list-container">
          {document.items.map((item: CoverListItem, index: number) => {
            return (
              <Box className="list-item" key={index}>
                <Box className={classNames("cover-type", item.cover_type)}>
                  Your {item.cover_type}
                </Box>
                <PrismicRichText field={item.content_paragraph.richText} />
                {item.is_add_on && <span className="optional">Optional</span>}
              </Box>
            );
          })}
        </Box>

        <Box className="cover-list-footnote">
          <PrismicRichText
            field={document.primary.cover_list_footnote.richText}
          />
        </Box>
      </Box>
    </CoverListContainer>
  );
};

export default CoverList;
